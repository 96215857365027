






import { Component, Vue } from 'vue-property-decorator'
import CustomerList from '@/components/CustomerList.vue'

@Component({
  components: {
    CustomerList
  }
})
export default class Customers extends Vue {}
